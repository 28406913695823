import Vue from 'vue'
import Vuex from 'vuex'
import userInfo from "./state/userInfo.state.js"
import cart from './state/cart.state.js'
Vue.use(Vuex)

export default new Vuex.Store({

  modules: {
    userInfo:userInfo,
    cart:cart
  }
})
