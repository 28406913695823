import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store/index.js"
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import( '../views/pages/login.vue')
  },
  {
    path:'/home',
    name:'home',
    component: ()=>import('../views/pages/home.vue')
  },
  {
    path:'/recharge',
    name:'recharge',
    component: ()=>import('../views/pages/recharge.vue')
  },
  {
    path: '/',
    name: 'layout',
    component: ()=>import("../views/pages/layout.vue"),
    redirect:"/index",
    children:[
      {
        path:"/index",
        name:"index",
        component:()=>import("../views/pages/index.vue")
      }
      ,
      {
        path:"/category",
        name:"category",
        component:()=>import("../views/pages/category.vue")
      },
     
      {
        path:"/order",
        name:"order",
        component:()=>import("../views/pages/order.vue")
      },
      {
        path:"/cart",
        name:"cart",
        component:()=>import("../views/pages/cart.vue")
      },
      {
        path:"/mine",
        name:"mine",
        component:()=>import("../views/pages/mine.vue")
      },
    ]
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to,from,next)=>{
  /**
   * to 从哪个页面来
   * from 到哪个页面去
   * next：只有执行next()才会进行跳转
   */
  // 判断用户是否登录
  console.log("store",store.state.userInfo)
  // const userInfo = JSON.parse(localStorage.getItem("userInfo"))
  const userInfo = store.state.userInfo.userInfo
  // console.log(userInfo)
  if(!userInfo.nickName){
    // 未登录，跳转到登录页面
    if(to.path==="/login"){
      next()
      return
    }
    next("/login")
  }else{
    
  }
  next()
})

export default router
