export default{
    state:{ 
        userInfo:(localStorage.getItem("userInfo")&&JSON.parse(localStorage.getItem("userInfo")))||{} 
    },
    mutations:{
        setUserInfo(state,uInfo){
            state.userInfo=uInfo
        }
    },

}