import Vue from 'vue'

const state={
	cartList:[]
}

const mutations={
	// 修改 添加 购物车商品
	/**
	 * 1、购物车已经有该商品：数量+1即可
	 * 2、购物车没有该商品：添加到购物车
	 */
	addGoodsItemMutation(state,goodsItem){
		const item = state.cartList.find(item=>item.id===goodsItem.id)
		if(item){
			// 存在
			item.count+=1;
		}else{
			// 不存在
			Vue.set(goodsItem,'count',1);
			Vue.set(goodsItem,'selectType','el-icon-success');
			state.cartList.push(goodsItem);
		}
	},
	// 修改数量
	changeCountMutation(state,{isAdd,index}){
		if(isAdd){
			if(state.cartList[index].count<999){
				state.cartList[index].count = parseInt(state.cartList[index].count)+1;
			}
			// parseInt(state.cartList[index].count)+=1;
			

		}else{
			if(state.cartList[index].count>1){
				state.cartList[index].count = parseInt(state.cartList[index].count)-1;
			}else{
				// 删除该商品
				state.cartList.splice(index,1);
				
			}
		}
	},
	// 修改商品选中状态
	changeSeleteTypeMutation(state,index){
		state.cartList[index].selectType = state.cartList[index].selectType == "el-icon-success"?"el-icon-circle-check":"el-icon-success";
	},
	// 商品全选状态修改
	changeAllSelectedMutation(state,isAllSelected){
		state.cartList.forEach(item=>item.selectType = isAllSelected == "el-icon-success"?"el-icon-circle-check":"el-icon-success")
	},
	// 移除已提交订单的商品
	removeOrderGoods(state){
		const newCartList = state.cartList.filter((item,index)=>{return item.selectType !== "el-icon-success"})
		// console.log(newCartList)
		state.cartList = newCartList
	}
}

const actions={
	
}

const getters={
	// 是否全选中
	isAllSelected(state){
		const result = state.cartList.every(item=>item.selectType == "el-icon-success")
		if(result){
			return "el-icon-success"
		}else{
			return "el-icon-circle-check"
		}
	},
	// 总数量
	totalCount(state){
		return state.cartList.reduce((pre,item)=>{
			return pre += item.selectType == "el-icon-success" ? parseInt(item.count):0
		},0)
	},
	// 总价格 Math.round(sums[index]*100)/100
	totalPrice(state){
		const totalPrice = state.cartList.reduce((pre,item)=>{
			return pre += item.selectType == "el-icon-success" ? item.price*parseInt(item.count) : 0
		},0)
		return totalPrice.toFixed(2)
	}
	
}

export default{
	namespaced:true,
	state,
	mutations,
	actions,
	getters,
}